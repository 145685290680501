import { APICore } from "./apiCore";

const api = new APICore();

export function getFoodTrucks() {
  const endpoint = "/properties/admin/food-trucks/";
  return api.get(`${endpoint}`, {});
}

export function getFoodTruckDetails(params: any) {
  const endpoint = `/properties/admin/food-trucks/${params.id}/`;
  return api.get(`${endpoint}`, {});
}
