import React from "react";
import { Card } from "react-bootstrap";

// components
import { useQuery } from "react-query";
import dayjs from "dayjs";
import { getSubscriptions } from "../../../helpers/api/subscription";
import Table from "../../../components/Table/Table";
import { startCase } from "lodash";

const SubscriptionList = () => {
  const columns = [
    {
      Header: "User",
      accessor: "user.full_name",
      sort: true,
    },
    {
      Header: "Name",
      accessor: "product.name",
      sort: true,
    },
    {
      Header: "Status",
      accessor: "status",
      sort: true,
      Cell: ({ row }: { row: any }) => startCase(row.original.status),
    },
    {
      Header: "Price",
      accessor: "product.price_amount",
      sort: true,
      Cell: ({ row }: { row: any }) => (
        <>${row.original.product.price_amount}</>
      ),
    },
    {
      Header: "Subscription Date",
      accessor: "created_at",
      sort: true,
      Cell: ({ row }: { row: any }) =>
        dayjs(row.original?.created_at).format("DD MMM, YYYY"),
    },
  ];

  const { data: events } = useQuery({
    queryKey: ["subscriptions"],
    queryFn: () => getSubscriptions(),
  });

  return (
    <>
      <Card>
        <Card.Body>
          <h4 className="header-title mb-4">All Subscriptions</h4>
          <Table
            columns={columns}
            data={events?.data?.data || []}
            pageSize={10}
            isSortable={true}
            pagination={true}
            isSearchable={true}
            tableClass="table-nowrap table-hover"
            searchBoxClass="my-2"
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default SubscriptionList;
