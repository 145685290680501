import React, { useRef } from "react";
import { Card, Dropdown } from "react-bootstrap";

import profileImg from "../../../assets/images/users/user-1.jpg";
import { RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { uploadFile } from "../../../helpers/api/utils";
import { getProfile, updateProfile } from "../../../helpers";
import { AUTH_SESSION_KEY } from "../../../helpers/api/apiCore";
import { authApiResponseSuccess } from "../../../redux/actions";
import { AuthActionTypes } from "../../../redux/auth/constants";

const UserBox = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  const { user } = useSelector((state: RootState) => ({
    user: state.Auth.user,
  }));

  const handleImageUpload = () => {
    // Trigger the file input click event
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files;
    try {
      if (file) {
        const image = await uploadFile(file);
        await updateProfile({ image: image.data.url });
        const profile = await getProfile();
        dispatch(
          authApiResponseSuccess(AuthActionTypes.LOGIN_USER, {
            ...profile.data.data,
            token: user.token,
          })
        );
        localStorage.setItem(
          AUTH_SESSION_KEY,
          JSON.stringify({
            ...profile.data.data,
            token: user.token,
          })
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteImage = async () => {
    try {
      await updateProfile({ image: "" });
      const profile = await getProfile();
      dispatch(
        authApiResponseSuccess(AuthActionTypes.LOGIN_USER, {
          ...profile.data.data,
          token: user.token,
        })
      );
      localStorage.setItem(
        AUTH_SESSION_KEY,
        JSON.stringify({
          ...profile.data.data,
          token: user.token,
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Card className="text-center">
      <Card.Body>
        {/* Hidden file input to trigger file selection */}
        <input
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          ref={fileInputRef}
          onChange={handleFileChange}
        />

        <div className="d-flex justify-content-center">
          <div className="position-relative" style={{ width: "9rem" }}>
            <Dropdown className="position-absolute end-0">
              <Dropdown.Toggle variant="link" id="dropdown-basic">
                <i className="mdi mdi-dots-vertical mdi-24px"></i>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={handleImageUpload}>
                  <i className="mdi mdi-camera me-1"></i>Update
                </Dropdown.Item>
                <Dropdown.Item onClick={handleDeleteImage}>
                  <i className="mdi mdi-delete me-1"></i>Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <img
              src={user.image || profileImg}
              className="rounded-circle avatar-lg img-thumbnail"
              alt=""
            />
          </div>
        </div>

        <h4 className="mb-0">{`${user.first_name} ${user.last_name}`}</h4>
        <p className="text-muted">@{user.username}</p>
        <div className="text-start mt-3">
          <p className="text-muted mb-2 font-13">
            <strong>Mobile :</strong>
            <span className="ms-2">{user.phone_number}</span>
          </p>

          <p className="text-muted mb-2 font-13">
            <strong>Email :</strong>
            <span className="ms-2 ">{user.email}</span>
          </p>
        </div>
      </Card.Body>
    </Card>
  );
};

export default UserBox;
