import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Button, FormControl } from "react-bootstrap";
import classNames from "classnames";
import _ from "lodash";

// components
import PageTitle from "../../../../components/Layout/PageTitle";
import Table from "../../../../components/Table/Table";

import AddCustomer from "./AddCustomer";

// dummy data
import { customers } from "./data";
import { useQuery } from "react-query";
import {
  createUser,
  getStaffUsers, // This should be adjusted to support search query.
  getUsers,
  updateUser,
} from "../../../../helpers/api/user";
import dayjs from "dayjs";
import EditCustomer from "./EditCustomer";

// status column render
const StatusColumn = ({ row }: { row: any }) => {
  return (
    <React.Fragment>
      <span
        className={classNames("badge", {
          "bg-soft-success text-success": row.original.status === "Active",
          "bg-soft-danger text-danger": row.original.status === "Blocked",
        })}
      >
        {row.original.status}
      </span>
    </React.Fragment>
  );
};

const AdminUsers = () => {
  const [show, setShow] = useState<boolean>(false);
  const onCloseModal = () => setShow(false);
  const onOpenModal = () => setShow(true);

  const [showEdit, setShowEdit] = useState<boolean>(false);
  const onCloseEditModal = () => setShowEdit(false);
  const onOpenEditModal = () => setShowEdit(true);
  const [currentUser, setCurrentUser] = useState(null);

  const [searchTerm, setSearchTerm] = useState<string>("");

  // Debounced search term using lodash
  const debouncedSearchTerm = useMemo(
    () => _.debounce((term: string) => setSearchTerm(term), 500),
    []
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSearchTerm(event.target.value);
  };

  const columns = [
    {
      Header: "User",
      accessor: "full_name",
      sort: true,
      classes: "table-user",
    },
    {
      Header: "Phone",
      accessor: "phone_number",
      sort: false,
    },
    {
      Header: "Email",
      accessor: "email",
      sort: false,
    },
    {
      Header: "Username",
      accessor: "username",
      sort: false,
    },
    {
      Header: "Joining Date",
      accessor: "date_joined",
      Cell: ({ row }: { row: any }) =>
        dayjs(row.original.date_joined).format("HH:mm a, MMM DD, YYYY"),
      sort: false,
    },
    {
      Header: "Status",
      accessor: "status",
      sort: false,
      Cell: StatusColumn,
    },
    {
      Header: "Action",
      accessor: "action",
      sort: false,
      classes: "table-action",
      Cell: (val: any) => (
        <React.Fragment>
          <Link
            onClick={() => {
              onOpenEditModal();
              setCurrentUser(val?.cell?.row?.original);
            }}
            to="#"
            className="action-icon"
          >
            <i className="mdi mdi-square-edit-outline"></i>
          </Link>
        </React.Fragment>
      ),
    },
  ];

  const { data: users, refetch } = useQuery({
    queryKey: ["locations", searchTerm],
    queryFn: () => getStaffUsers({ search: searchTerm }),
    enabled: true,
  });

  const onSubmit = async (data: any) => {
    try {
      const payload = {
        ...data,
        is_superuser: true,
      };
      await createUser(payload);
      setShow(false);
      refetch();
    } catch (error) {
      console.error("Error creating user:", error);
    }
  };

  const onEditSubmit = async (data: any) => {
    try {
      const payload = {
        ...data,
        is_superuser: true,
      };
      await updateUser(payload);
      setShowEdit(false);
      refetch();
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          { label: "CRM", path: "/truck/booked-events" },
          { label: "Admin Users", path: "/truck/booked-events", active: true },
        ]}
        title={"Admin Users"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Row className="mb-2">
                <Col sm={2}>
                  <div className="text-sm-start mt-2 mt-sm-0">
                    <FormControl
                      type="text"
                      placeholder="Search by Name, Email, or Username"
                      onChange={handleSearchChange}
                    />
                  </div>
                </Col>

                <Col sm={10} className="text-sm-end">
                  <Button onClick={onOpenModal}>Add Admin</Button>
                </Col>
              </Row>

              <Table
                columns={columns}
                data={users?.data.data || []}
                pageSize={12}
                isSortable={true}
                pagination={true}
                isSelectable={true}
                tableClass="table-nowrap table-striped"
                noDataText="No data found"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Add customer modal */}
      <AddCustomer show={show} onHide={onCloseModal} onSubmit={onSubmit} />
      <EditCustomer
        show={showEdit}
        onHide={onCloseEditModal}
        onSubmit={onEditSubmit}
        defaultValue={currentUser}
      />
    </React.Fragment>
  );
};

export default AdminUsers;
