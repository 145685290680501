import { FileType } from "../../components/Form/FileUploader";
import { APICore } from "./apiCore";

const api = new APICore();

interface DownloadFile {
  data: any;
  filename: string;
  mime: any;
  bom: any;
}

const downloadFile = ({ data, filename, mime, bom }: DownloadFile) => {
  var blobData = typeof bom !== "undefined" ? [bom, data] : [data];
  var blob = new Blob(blobData, { type: mime || "application/octet-stream" });

  var blobURL =
    window.URL && window.URL.createObjectURL
      ? window.URL.createObjectURL(blob)
      : window.webkitURL.createObjectURL(blob);
  var tempLink = document.createElement("a");
  tempLink.style.display = "none";
  tempLink.href = blobURL;
  tempLink.setAttribute("download", filename);

  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  if (typeof tempLink.download === "undefined") {
    tempLink.setAttribute("target", "_blank");
  }

  document.body.appendChild(tempLink);
  tempLink.click();

  // Fixes "webkit blob resource error 1"
  setTimeout(function () {
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(blobURL);
  }, 200);
};

const uploadFile: any = async (file: File | null) => {
  try {
    const apiUrl = "/document-upload/";
    const response = await api.createWithFile(apiUrl, file);
    return response.data;
  } catch (error) {
    console.error("Error uploading files:", error);
  }
};

export { downloadFile, uploadFile };
