import { Modal, Button } from "react-bootstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// components
import { useForm } from "react-hook-form";
import { FormInput } from "../../../components";

interface AddProductProps {
  show: boolean;
  onHide: () => void;
  onSubmit: (value: any) => void;
  selectedEvent: any;
}

const AddProduct = ({
  show,
  onHide,
  onSubmit,
  selectedEvent,
}: AddProductProps) => {
  const schemaResolver = yupResolver(
    yup.object().shape({
      name: yup.string().required(),
      description: yup.string().required(),
      interval_count: yup.string().required(),
      price: yup.number().required().min(0),
    })
  );

  const methods = useForm({ resolver: schemaResolver });

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = methods;

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="bg-light" onHide={onHide} closeButton>
          <Modal.Title className="m-0">Add Product</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormInput
              label="Name"
              type="text"
              name="name"
              placeholder="Enter name"
              containerClass={"mb-3"}
              register={register}
              key="name"
              errors={errors}
              control={control}
            />
            <FormInput
              label="Description"
              type="textarea"
              name="description"
              placeholder="Enter description"
              containerClass={"mb-3"}
              register={register}
              key="description"
              errors={errors}
              control={control}
            />
            <FormInput
              type="number"
              name="price"
              label="Price"
              placeholder="Price"
              containerClass={"mb-3"}
              register={register}
              key="price"
              errors={errors}
              control={control}
            />
            <FormInput
              type="number"
              name="interval_count"
              label="Interval (Months)"
              placeholder="Interval"
              containerClass={"mb-3"}
              register={register}
              key="interval_count"
              errors={errors}
              control={control}
            />
            <div className="text-end">
              <Button
                variant="success"
                type="submit"
                className="waves-effect waves-light me-1"
              >
                Save
              </Button>
              <Button
                variant="danger"
                type="submit"
                className="waves-effect waves-light"
                onClick={onHide}
              >
                Cancel
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddProduct;
