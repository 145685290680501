import React from "react";
import { Row, Col, Card } from "react-bootstrap";

// components

import UserBox from "./UserBox";
import Settings from "./Settings";
import PageTitle from "../../../components/Layout/PageTitle";

const MyProfile = () => {
  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Profile", path: "/settings/my-account", active: true },
        ]}
        title={"Profile"}
      />
      <Row>
        <Col xl={4} lg={4}>
          <UserBox />
        </Col>
        <Col xl={8} lg={8}>
          <Card>
            <Card.Body>
              <Settings />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default MyProfile;
