import React, { useState } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";

import AddEvents from "./AddProduct";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { createProduct, getProducts } from "../../../helpers/api/subscription";
import Table from "../../../components/Table/Table";

const EventsList = () => {
  const [show, setShow] = useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useState<any>();
  const onCloseModal = () => {
    setSelectedEvent(null);
    setShow(false);
  };

  const onOpenModal = () => setShow(true);
  const columns = [
    {
      Header: "Title",
      accessor: "name",
      sort: true,
    },
    {
      Header: "Price",
      accessor: "price_amount",
      sort: true,
    },
    {
      Header: "Interval (Months)",
      accessor: "interval_count",
      sort: true,
    },
    // {
    //   Header: "Action",
    //   accessor: "action",
    //   sort: false,
    //   Cell: ({ row }: { row: any }) => (
    //     <>
    //       <Link
    //         to="#"
    //         className="action-icon"
    //         onClick={() => {
    //           setSelectedEvent(row.original);
    //           onOpenModal();
    //         }}
    //       >
    //         <i className="mdi mdi-square-edit-outline"></i>
    //       </Link>
    //       {/* <Link to="#" className="action-icon">
    //         <i className="mdi mdi-delete"></i>
    //       </Link> */}
    //     </>
    //   ),
    // },
  ];

  const queryClient = useQueryClient();

  const { data: events, isLoading } = useQuery({
    queryKey: ["products"],
    queryFn: () => getProducts(),
  });

  const { mutateAsync: createProductAsync, isLoading: createLoading } =
    useMutation({
      mutationFn: createProduct,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["products"] });
      },
    });

  const onSubmit = async (data: any) => {
    try {
      const payload = {
        ...data,
        interval: "month",
      };
      await createProductAsync(payload);
      setShow(false);
    } catch (error) {
      console.error("Error creating event:", error);
    }
  };

  return (
    <>
      <Card>
        <Card.Body>
          <Row className="justify-content-between">
            <Col>
              <Button
                variant="danger"
                className="waves-effect waves-light mb-2"
                onClick={onOpenModal}
                disabled={isLoading || createLoading}
              >
                Add Product
              </Button>
            </Col>
            <Col>
            </Col>
          </Row>
          <Table
            columns={columns}
            data={events?.data?.data || []}
            pageSize={10}
            isSortable={true}
            pagination={true}
            isSearchable={true}
            tableClass="table-nowrap table-hover"
            searchBoxClass="my-2"
          />
        </Card.Body>
      </Card>

      <AddEvents
        show={show}
        onHide={onCloseModal}
        onSubmit={onSubmit}
        selectedEvent={selectedEvent}
      />
    </>
  );
};

export default EventsList;
