import { APICore } from "./apiCore";

const api = new APICore();

export function getNotifications() {
  const endpoint = "/notifications/user/notifications/";
  return api.get(`${endpoint}`, { page_size: 0 });
}

export function updateNotification(notification: any) {
  const endpoint = `/notifications/user/notifications/${notification.id}/`;
  return api.updatePatch(endpoint, { is_read: true });
}
