import React from "react";
import { Row, Col } from "react-bootstrap";

import SubscriptionProductList from "./SubscriptionProductList";
import PageTitle from "../../../components/Layout/PageTitle";

const SubscriptionProducts = () => {
  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "CRM", path: "/location/my-schedules" },
          {
            label: "Subscription Products",
            path: "/location/my-schedules",
            active: true,
          },
        ]}
        title={"Subscription Products"}
      />

      <Row>
        <Col lg={12}>
          <SubscriptionProductList />
        </Col>
      </Row>
    </>
  );
};

export default SubscriptionProducts;
