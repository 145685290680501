import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AuthLayout from "../../auth/AuthLayout";

const CrossIcon = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 130.2 130.2"
    >
      {" "}
      <circle
        className="path circle"
        fill="none"
        stroke="#ff0000"
        strokeWidth="6"
        strokeMiterlimit="10"
        cx="65.1"
        cy="65.1"
        r="62.1"
      />{" "}
      <line
        className="path check"
        fill="none"
        stroke="#ff0000"
        strokeWidth="6"
        strokeLinecap="round"
        strokeMiterlimit="10"
        x1="38.2"
        y1="38.2"
        x2="92"
        y2="92"
      />{" "}
      <line
        className="path check"
        fill="none"
        stroke="#ff0000"
        strokeWidth="6"
        strokeLinecap="round"
        strokeMiterlimit="10"
        x1="92"
        y1="38.2"
        x2="38.2"
        y2="92"
      />{" "}
    </svg>
  );
};

const BottomLink = () => {
  const { t } = useTranslation();
  return (
    <Row className="mt-3">
      <Col className="text-center">
        <p className="text-white-50">
          {t("Back to")}{" "}
          <Link to={"/apps/subscription/subscribe"} className="text-white ms-1">
            <b>{t("Subscribe")}</b>
          </Link>
        </p>
      </Col>
    </Row>
  );
};

const Failure = () => {
  const { t } = useTranslation();

  return (
    <>
      <AuthLayout bottomLinks={<BottomLink />}>
        <div className="text-center">
          <div className="mt-4">
            <div className="logout-checkmark">
              <CrossIcon />
            </div>
          </div>

          <h3>{t("Subscription Failed")}</h3>

          <p className="text-muted">
            {" "}
            {t("Oops! Something went wrong. Please try again later.")}{" "}
          </p>
        </div>
      </AuthLayout>
    </>
  );
};

export default Failure;
